.content-block-split-width {
  &__product--image {
    .product-flag {
      #{$ldirection}: 20px;
      width: auto;
      top: 0;
      &__badge {
        background: 0 0;
        border: 0;
        color: $color-neutral-dark-gray;
        font-size: 12px;
        letter-spacing: 0.05em;
        line-height: 1.5;
        text-transform: uppercase;
        width: 100%;
        justify-content: flex-start;
        .mpp-container--dark-background & {
          color: $color-white;
        }
      }
    }
  }
  &.product-brief--show-shade-size {
    .content-block-split-width__product--size {
      margin-block-start: 15px;
      .sku-list {
        padding-block-end: 0;
        border-bottom: none;
      }
      .product-brief__sku {
        a.button {
          width: 75px;
          padding: 5px;
        }
      }
      .product-brief__shade-picker {
        width: 100%;
      }
      .product-shade-picker__shade {
        max-width: 34px;
        &-inner {
          width: 28px;
        }
      }
    }
    .content-block-split-width__product--atb {
      .button {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid $color-neutral-dark-gray;
        color: $color-neutral-dark-gray;
        min-width: auto;
        padding: 0;
        width: auto;
      }
    }
  }
}
