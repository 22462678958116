.content-block-split-width.asymmetrical-feature__product {
  display: grid !important;
  @include fluid-sizing(column-gap, 1px, 253px, $medium-up, $desktop-site-width, 'width', false);
  @include fluid-sizing(padding-#{$ldirection}, 20px, 40px, $medium-up, $landscape-up, 'width', false);
  padding: 0;
  &:hover {
    .column {
      &__1,
      &__2 {
        &.content-block-split-width__product--quickshop {
          opacity: 1;
          transition: all $transition-effect $transition-duration;
        }
      }
    }
  }
  &.mpp-feature-block {
    @include fluid-sizing(padding-#{$ldirection}, 1px, 20px, $medium-up, $landscape-up, 'width', false);
    @include fluid-sizing(padding-#{$rdirection}, 1px, 40px, $medium-up, $landscape-up, 'width', false);
    @include fluid-sizing(column-gap, 1px, 143px, $medium-up, $desktop-site-width, 'width', false);
    padding: 0;
    @include breakpoint($medium-up) {
      grid-template-columns: [col1] 7fr [col2] 620px;
    }
    &.column-layout--reversed {
      @include breakpoint($medium-up) {
        grid-template-columns: [col1] 620px [col2] 7fr;
      }
    }
  }
  @include breakpoint($medium-up) {
    grid-template-rows: [title] 0fr [body] 0fr [cta] 0fr [image] 1fr [footer] auto;
    grid-template-columns: [col1] 7fr [col2] 720px;
    &.column-layout--reversed {
      grid-template-columns: [col1] 720px [col2] 7fr;
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 40px;
    }
  }
  @include breakpoint($mobile-to-desktop) {
    grid-template-columns: [col1] 2fr [col2] calc(50% + 10px);
  }
  @include breakpoint($desktop-site-width) {
    grid-template-columns: [col1] 7fr [col2] 720px;
  }
  .column {
    &__1,
    &__2 {
      &.content-block-split-width {
        &__title {
          padding-top: 0;
          padding-bottom: 0;
        }
        &__body {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &__cta {
          padding-top: 20px;
          padding-bottom: 40px;
          @include breakpoint($medium-up) {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
        &__container-link {
          @include breakpoint($medium-up) {
            grid-row: 1 /span 4;
          }
        }
        &__hero-image {
          $horizontal-positions: left, center, right;
          $vertical-positions: top, center, bottom;
          grid-row: 4;
          margin-top: auto;
          @include breakpoint($medium-up) {
            grid-row: 1/5;
            max-width: 100%;
            overflow: hidden;
          }
          .mantle-media-asset {
            height: 100%;
          }
          img {
            aspect-ratio: 4 / 5;
            object-fit: cover;
            width: 100%;
            @include breakpoint($mobile-to-desktop) {
              height: 100%;
              object-fit: cover;
              width: auto;
              object-position: center;
            }
            @include breakpoint($medium-up) {
              max-width: 100%;
              height: auto;
              object-position: center $rdirection;
              object-fit: cover;
            }
          }
          @each $h-position in $horizontal-positions {
            @each $v-position in $vertical-positions {
              &--#{$v-position}-#{$h-position} img {
                @include breakpoint($mobile-to-desktop) {
                  object-position: $v-position $h-position;
                }
              }
            }
          }
        }
        &__product {
          grid-row: 7;
          @include breakpoint($medium-up) {
            padding: 0;
            grid-row: 4;
          }
          &--title,
          &--description,
          &--pricing,
          &--cta,
          &--atb,
          &--size,
          &--quickshop {
            padding-#{$ldirection}: 0;
            padding-#{$rdirection}: 15px;
            padding-top: 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            @include breakpoint($medium-up) {
              @include x-padding(0);
              margin-#{$ldirection}: 0;
              align-items: flex-start;
              justify-content: flex-start;
            }
            .mantle-custom-text,
            .button,
            .dynamic-fill,
            .product-brief__sku,
            a {
              width: 250px;
              @include fluid-sizing(width, 250px, 295px, $iphonex-width, $med-small-up, 'width', false);
              @include fluid-sizing(width, 295px, 415px, $med-small-up, $medium-up, 'width', false);
              @include fluid-sizing(width, 211px, 430px, $medium-up, $desktop-site-width, 'width', false);
            }
          }
          &--image {
            grid-row: 7;
            padding: 20px 0;
            position: relative;
            @include fluid-sizing(height, 333px, 538px, $iphonex-width, $medium-up);
            @include fluid-sizing(width, 265px, 430px, $iphonex-width, $medium-up);
            @include fluid-sizing(width, 211px, 430px, $medium-up, $desktop-site-width, 'width', false);
            @include fluid-sizing(height, 265px, 538px, $medium-up, $desktop-site-width, 'width', false);
            @include breakpoint($medium-up) {
              padding: 0 0 8px;
              grid-row: 3;
              overflow: hidden;
            }
            img {
              width: 100%;
              aspect-ratio: 4 / 5;
              @include breakpoint($mobile-to-desktop) {
                object-fit: cover;
                width: 100%;
                width: -webkit-fill-available;
                height: 100%;
                height: -webkit-fill-available;
              }
              @include breakpoint($med-small-up) {
                object-fit: contain;
                object-position: center $ldirection;
                padding: 0;
              }
              @include breakpoint($desktop-site-width) {
                object-fit: contain;
              }
              &.product-image {
                transition: all $transition-effect $transition-duration;
                &__base {
                  opacity: 1;
                }
                &__hover {
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  $ldirection: 0;
                }
                &:hover {
                  &.product-image {
                    transition: all $transition-effect $transition-duration;
                    &__base {
                      opacity: 0;
                    }
                    &__hover {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
          &--title {
            grid-row: 8;
            padding-top: 8px;
            padding-bottom: 0;
            @include breakpoint($medium-up) {
              grid-row: 5;
            }
          }
          &--description {
            grid-row: 9;
            @include breakpoint($medium-up) {
              grid-row: 7;
              padding-bottom: 8px;
            }
          }
          &--pricing {
            grid-row: 10;
            @include breakpoint($medium-up) {
              grid-row: 8;
              padding-bottom: 8px;
            }
          }
          &--cta {
            grid-row: 11;
            @include breakpoint($medium-up) {
              grid-row: 9;
            }
          }
          &--quickshop {
            grid-row: 12;
            @include breakpoint($medium-up) {
              grid-row: 10;
              opacity: 0;
              transition: all $transition-effect $transition-duration;
            }
          }
          &--atb {
            grid-row: 13;
            padding-top: 10px;
            @include breakpoint($medium-up) {
              grid-row: 11;
            }
          }
        }
        &__caption {
          grid-row: 5/6;
          border-bottom: none;
          display: block;
          @include x-padding(20px);
          @include breakpoint($medium-up) {
            @include x-padding(0);
            grid-row: 5/8;
          }
          .style--bare {
            border-bottom: none;
          }
        }
      }
    }
    &__1 {
      &.content-block-split-width {
        &__title {
          grid-row: 1;
          @include x-padding(20px);
          @include breakpoint($medium-up) {
            padding: 0;
            grid-row: 1;
          }
        }
        &__body {
          @include x-padding(20px);
          grid-area: 2;
          max-width: 100%;
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 0 40px 10px 0);
            position: relative;
            top: 10px;
            grid-row: 2;
          }
          @include breakpoint($desktop-site-width) {
            grid-row: 2;
            @include swap_direction(padding, 10px 40px 10px 0);
          }
        }
        &__cta {
          @include x-padding(20px);
          grid-row: 3;
          max-width: 100%;
          @include breakpoint($medium-up) {
            grid-row: 3;
            @include swap_direction(padding, 10px 40px 10px 0);
          }
        }
        &__product {
          align-items: flex-end;
          margin-top: 40px;
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 0;
            align-items: flex-start;
            padding: 40px 0 0;
            margin-top: auto;
          }
          &--image {
            padding: 20px 0;
            grid-row: 5;
            @include breakpoint($medium-up) {
              padding: 0;
              grid-row: 3;
              margin: 0;
            }
          }
        }
      }
    }
    &__all {
      grid-column: 1 / span 2;
      &.content-block-split-width {
        &__quickshop {
          grid-row: 13;
          @include breakpoint($medium-up) {
            grid-row: 11;
          }
          .product-quickshop {
            margin-top: 5px;
          }
        }
      }
    }
  }
  &.product-brief--show-shade-size {
    .content-block-split-width {
      &__product--size {
        grid-row: 8;
        @include breakpoint($medium-up) {
          grid-row: 5;
        }
      }
      &__product--title {
        grid-row: 9;
        @include breakpoint($medium-up) {
          grid-row: 6;
        }
      }
      &__product--description {
        grid-row: 10;
        @include breakpoint($medium-up) {
          grid-row: 7;
        }
      }
      &__product--pricing {
        grid-row: 11;
        @include breakpoint($medium-up) {
          grid-row: 8;
        }
      }
      &__product--atb {
        a.button,
        div.button {
          width: auto;
          &--disabled {
            border-bottom: none;
          }
        }
      }
    }
  }
}
